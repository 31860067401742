import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Я сделал правильный выбор, выбрав Fitclub, где мне составили правильный план и программу, я уже добился своего идеального тела!",
    name: 'АЛЕКСАНДР ФАДЕЕВ',
    status : 'ПРЕДПРИНИМАТЕЛЬ'
  },
  {
    image: image2,
    review: 'Это лучший фитнесс-клуб, в котором мне довелось работать! Лучшее оснащение в городе!',
    name: 'МАКСИМ ПЕТРОВ',
    status: 'ТРЕНЕР'
  },
  {
    image : image3,
    review:'Очень внимательный персонал, чисто и комфортно заниматься',
    name: 'ФЁДОР',
    status: "КЛИЕНТ"
  }
];
